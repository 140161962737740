import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { NovelManiaAPI } from '../ShopModal.component';

interface IProps {
  show: boolean;
  pack: any;
  goBack: () => void;
  closeModal: () => void;
}

const PurchasePlan: React.FC<IProps> = (props) => {
  const { show, goBack, pack, closeModal } = props;

  return (
    show && (
      <>
        <div className="modal-purchase">
          <div className="purchase-body">
            <a
              className="inner-close-btn inner-close-btn-left js-change-tier-btn"
              onClick={goBack}
            >
              <i className="fa fa-arrow-left fa-lg"></i>
            </a>
            <a
              className="inner-close-btn inner-close-btn-right hide-overlay"
              onClick={closeModal}
            >
              <i className="fa fa-times fa-lg"></i>
            </a>
            <div className="body-left">{props.children}</div>
            <div className="body-right">
              <div className="tier-thumb">
                <i className="ico sp-aw-webink-vial"></i>
              </div>
              <ul className="payment-detail">
                <li className="payment-detail-item">
                  <span className="item-label">{pack.coins} Moedas</span>
                  <span className="item-price">R$ {pack.amount}</span>
                </li>
                <li className="payment-detail-item">
                  <span className="item-label">Total</span>
                  <span className="item-price item-price--total">
                    <span className="currency">R$ {pack.amount}</span>
                  </span>
                </li>
              </ul>
              <p className="body-desc">
                Moedas são utulizadas para desbloquear capítulos ou apoiar
                colaboradores. São atreladas à sua conta, podendo ser utilizadas
                em qualquer dispositivo.
              </p>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default PurchasePlan;
