import React, { useEffect, useState } from 'react';
import { Form, Input } from 'antd';

interface IProps {
  show: boolean;
  user: any;
}

import '../../../packs/antd.scss';

const PurchaseForm: React.FC<IProps> = (props) => {
  const { show, user } = props;
  console.log(user);

  return (
    show && (
      <>
        <Form className="antd">
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </>
    )
  );
};

export default PurchaseForm;
