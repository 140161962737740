import * as WOW from './wow.min';

import './owl.carousel.min';

$(() => {
  wow();
  carousel();
  search();
  rating();

  $('[data-toggle="tooltip"]').tooltip();

  $('.dropdown-novel').click(function () {
    $('.menu').toggle();
  });
  /*=================================
	=            Avaliação            =
	=================================*/
  function rating() {
    $('.evaluation-individual').on('mouseover', function () {
      var idStar;
      idStar = '#' + $(this).find('.vote').attr('id');

      /* 1. Visualizing things on Hover - See next part for action on click */
      $(idStar + ' li')
        .on('mouseover', function () {
          var onStar = parseInt($(this).data('value'), 10); // The star currently mouse on

          // Now highlight all the stars that's not after the current hovered star
          $(this)
            .parent()
            .children('li.star')
            .each(function (e) {
              if (e < onStar) {
                $(this).addClass('hover');
              } else {
                $(this).removeClass('hover');
              }
            });
        })
        .on('mouseout', function () {
          $(this)
            .parent()
            .children('li.star')
            .each(function (e) {
              $(this).removeClass('hover');
            });
        });
      /* 2. Action to perform on click */
      $(idStar + ' li').on('click', function () {
        var onStar = parseInt($(this).data('value'), 10); // The star currently selected
        var stars = $(this).parent().children('li.star');

        for (i = 0; i < stars.length; i++) {
          $(stars[i]).removeClass('selected');
        }

        for (i = 0; i < onStar; i++) {
          $(stars[i]).addClass('selected');
        }

        // JUST RESPONSE (Not needed)
        var ratingValue = parseInt(
          $(idStar + ' li.selected')
            .last()
            .data('value'),
          10
        );
        var msg = '';

        if (ratingValue > 1) {
          msg = 'Obrigado! Sua avaliação é super importante.';
        } else {
          msg = 'Esperamos que tenha uma melhor avaliação futuramente.';
        }
        $(idStar + ' .result > small').html(ratingValue);
        responseMessage(msg, ratingValue);
      });
    });
  }

  function responseMessage(msg, value) {
    $('.success-box').fadeIn(200);
    $('.success-box div.text-message').html('<span>' + msg + '</span>');
  }

  function setSizes() {
    wh = $w.height();
    h = $('body').height();
    sHeight = h - wh;
  }

  function wow() {
    new WOW().init();
  }

  /*----------  Carousel owl  ----------*/
  function carousel() {
    // tns({
    //   container: '.novels-slider-m-col2',
    //   items: 5,
    //   autoplayButton: false,
    //   mouseDrag: true,
    //   loop: true,
    //   dots: false,
    //   nav: true,
    //   margin: 20,
    //   gutter: 100,
    //   autoplayTimeout: 10000,
    //   responsive: {
    //     0: {
    //       items: 2,
    //     },
    //     768: {
    //       items: 3,
    //     },
    //     992: {
    //       items: 4,
    //     },
    //     1300: {
    //       items: 5,
    //     },
    //   },
    // });

    $('.slider').owlCarousel({
      items: 1,
      loop: true,
      dots: true,
      autoplayTimeout: 10000,
    });

    //Mobile views grid
    $('.novels-slider-m-col2').owlCarousel({
      items: 5,
      loop: true,
      dots: false,
      nav: true,
      margin: 20,
      autoplayTimeout: 10000,
      responsive: {
        0: {
          items: 2,
        },
        768: {
          items: 3,
        },
        992: {
          items: 4,
        },
        1300: {
          items: 5,
        },
      },
    });
    $('.novels-slider-col5').owlCarousel({
      items: 5,
      loop: true,
      dots: false,
      nav: true,
      margin: 20,
      autoplayTimeout: 10000,
      responsive: {
        0: {
          items: 1,
        },
        440: {
          items: 2,
        },
        768: {
          items: 3,
        },
        992: {
          items: 4,
        },
        1300: {
          items: 5,
        },
      },
    });
    $('.novels-slider-col2').owlCarousel({
      items: 4,
      loop: true,
      dots: false,
      nav: false,
      margin: 20,
      autoplayTimeout: 6000,
      responsive: {
        0: {
          items: 2,
        },
        500: {
          items: 3,
        },
        992: {
          items: 4,
        },
        1200: {
          items: 5,
        },
      },
    });
  }

  /*----------  Search click  ----------*/
  function search() {
    $('.search-click').on('click', function () {
      $('.search-open').delay(50).fadeIn();
    });

    $('.search-open .close').on('click', function () {
      $('.search-open').delay(50).fadeOut();
    });
  }
});
