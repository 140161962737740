import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { NovelManiaAPI } from '../ShopModal.component';

interface IProps {
  show: boolean;
  handlePaymentMethod: (paymentMethod) => void;
}

const PlanInfo: React.FC<IProps> = (props) => {
  const { show, handlePaymentMethod } = props;

  return (
    show && (
      <>
        <p className="body-label">Escolha seu método de pagamento</p>
        <ul className="payments">
          <li className="payment">
            <a
              className="payment__item credit-card p-btn"
              onClick={() => handlePaymentMethod('credit_card')}
            >
              <span className="btn-label">Cartão de crédito</span>
            </a>
            <a
              className="payment__item boleto-pix p-btn p-btn-second"
              onClick={() => handlePaymentMethod('boleto')}
            >
              <span className="btn-label">Boleto ou Pix</span>
            </a>
          </li>
        </ul>
      </>
    )
  );
};

export default PlanInfo;
