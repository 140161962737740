$(() => {
  //Rolagem na página até o fim da leitura
  var wind = $(window);
  var header = $('nav.navbar');
  var footer = $('.novel-footer');
  var progCount = $('.progress-count');
  var prog = $('.progress-indicator-2');
  var wh, h, sHeight;

  //Ignorar altura do header e footer
  var noElements = footer.height() + header.height();

  function setSizes() {
    wh = wind.height();
    h = $('body').height();
    sHeight = h - wh;
  }

  wind
    .on('scroll', function () {
      var perc = Math.max(
        0,
        Math.min(1, wind.scrollTop() / (sHeight - noElements))
      );
      updateProgress(perc);
    })
    .on('resize', function () {
      setSizes();
      wind.trigger('scroll');
    });

  setSizes();

  function updateProgress(perc) {
    progCount.html(Math.round(perc * 100) + '%');
    prog.css({ width: perc * 100 + '%' });
  }
});
