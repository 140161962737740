import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

export const NovelManiaAPI = axios.create({
  baseURL: '/api/v1/',
});

const useShop = () => {
  const [step, setStep] = useState(1);
  const [user, setUser] = useState(null);
  const [packs, setPacks] = useState([]);
  const [selectedPack, setSelectedPack] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  useEffect(() => {
    fetchUser();
    fetchPacks();
  }, []);

  const fetchUser = async () => {
    const response = await NovelManiaAPI.get('/users/me');

    setUser(response.data);
  };

  const fetchPacks = async () => {
    const response = await NovelManiaAPI.get('/packs');

    setPacks(response.data);
  };

  const handleSelectPack = (pack) => {
    setSelectedPack(pack);
    setStep(2);
  };

  const handlePaymentMethod = (paymentMehtod) => {
    setSelectedPaymentMethod(paymentMehtod);
    setStep(3);
  };

  const handleCloseModal = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById('shop-section'));
  };
  return {
    step,
    setStep,
    user,
    packs,
    selectedPack,
    handleSelectPack,
    handleCloseModal,
    selectedPaymentMethod,
    handlePaymentMethod,
  };
};

export default useShop;
