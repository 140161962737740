$(() => {
  const bodySection = $('body');
  const theme = localStorage.getItem('theme');

  if (localStorage.getItem('font-size')) {
    setFontSize(localStorage.getItem('font-size'));
  }

  if (theme && theme === 'dark') {
    bodySection.addClass('dark');
  } else {
    bodySection.removeClass('dark');
  }

  if (localStorage.getItem('text-align') === 'justify') {
    $('#align-text span i')
      .removeClass('fa-align-left')
      .addClass('fa-align-justify');
    $('#chapter-content').css('text-align', localStorage.getItem('text-align'));
  } else {
    $('#chapter-content').css('text-align', localStorage.getItem('text-align'));
  }

  /**
   * SETTING BUTTONS
   */

  $('#dark-light').click(function () {
    if (bodySection.hasClass('dark')) {
      bodySection.removeClass('dark');

      localStorage.setItem('theme', 'light');
    } else {
      bodySection.addClass('dark');

      localStorage.setItem('theme', 'dark');
    }
  });

  $('#inc-font').click(function () {
    var currentSize =
      localStorage.getItem('font-size') ||
      $('#chapter-content').css('font-size');
    var newFontSize = parseFloat(currentSize) + 1;
    setFontSize(newFontSize);
    return false;
  });

  $('#dec-font').click(function () {
    var currentSize =
      localStorage.getItem('font-size') ||
      $('#chapter-content').css('font-size');
    var newFontSize = parseFloat(currentSize) - 1;
    setFontSize(newFontSize);
    return false;
  });

  $('#align-text').click(function () {
    if (localStorage.getItem('text-align') === 'justify') {
      localStorage.setItem('text-align', 'left');
      $('#align-text span i')
        .removeClass('fa-align-justify')
        .addClass('fa-align-left');
    } else {
      localStorage.setItem('text-align', 'justify');
      $('#align-text span i')
        .removeClass('fa-align-left')
        .addClass('fa-align-justify');
    }

    jQuery.each(
      $('#chapter-content p').not(
        '[style*="text-align:center"], [style*="text-align: center"], [style*="text-align:right"], [style*="text-align: right"]'
      ),
      function () {
        $(this).css('text-align', localStorage.getItem('text-align'));
      }
    );
  });

  function setFontSize(size) {
    if (size >= 12 && size <= 20) {
      $('#chapter-content').css('font-size', parseFloat(size));
      localStorage.setItem('font-size', size);
    }
  }

  $("p[data-f-id='pbf'").ready(function () {
    $("p[data-f-id='pbf']").remove();
  });
});
