import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import AddressInfo from './PurchasePlan/AddressInfo.component';
import ModalCoins from './ModalCoins/ModalCoins.component';
import PlanInfo from './PurchasePlan/PlanInfo.component';
import PurchasePlan from './PurchasePlan/PurchasePlan.component';
import UserInfo from './PurchasePlan/UserInfo.component';
import useShop from './ShopModal.use';
import Form from './PurchasePlan/PurchaseForm.component';

export const NovelManiaAPI = axios.create({
  baseURL: '/api/v1/',
});

const ShopModal: React.FC = () => {
  const [backdropRef, setBackdropRef] = useState<HTMLDivElement>();
  const {
    handleCloseModal,
    selectedPack,
    user,
    packs,
    step,
    setStep,
    handleSelectPack,
    handlePaymentMethod,
  } = useShop();

  const handleClickOutside = (event: React.SyntheticEvent) => {
    if (event.target === backdropRef) handleCloseModal();
  };

  return (
    <>
      <div
        className="modal-backdrop"
        id="#backdrop"
        ref={(ref) => setBackdropRef(ref)}
        onClick={handleClickOutside}
      >
        <ModalCoins
          show={step === 1}
          packs={packs}
          user={user}
          selectPack={handleSelectPack}
          closeModal={handleCloseModal}
        />
        <PurchasePlan
          show={step >= 2}
          goBack={() => setStep(1)}
          pack={selectedPack}
          closeModal={handleCloseModal}
        >
          <PlanInfo
            show={step === 2}
            handlePaymentMethod={handlePaymentMethod}
          />
          <Form show={step === 3} user={user} />
        </PurchasePlan>
      </div>
    </>
  );
};

export default ShopModal;
