import React from 'react';
import ShopModal from '../components/ShopModal/ShopModal.component';
import ReactDOM from 'react-dom';

document.addEventListener('DOMContentLoaded', () => {
  $('.open-coins-shop').on('click', () => {
    ReactDOM.render(<ShopModal />, document.getElementById('shop-section'));
  });
});

// $(function () {
//   const payload = {};

//   const sectionShop = () => $('#shop-section');
//   const modalShop = () => $('#shop-modal');
//   const paymentsTemplate = (id) => $(`#modalBuyPlan-${id}`);
//   const userInfoTemaplate = () => $('#user-info-template');
//   const addressTemplate = () => $('#address-template');

//   function openPlansModal() {
//     $.get('/current_user_info.json').done((response) => {
//       sectionShop().html(response.modal);

//       $('html').css('overflow', 'hidden');
//     });
//   }

//   function closePlansModal() {
//     sectionShop().html('');
//     $('html').css('overflow', 'auto');
//   }

//   function openUserInfo(type) {
//     payload.type = type;

//     console.log(payload);

//     $('.modal-purchase .body-left').html(userInfoTemaplate().html());
//   }

//   function openAddressInfo() {
//     let hasError = false;

//     if (!payload.user_email) {
//       $(`#user_email`)
//         .addClass('is-invalid')
//         .after(
//           '<div class="invalid-feedback">O campo e-mail é obrigatório!</div>'
//         );
//       hasError = true;
//     }

//     if (!payload.user_name) {
//       $(`#user_name`)
//         .addClass('is-invalid')
//         .after(
//           '<div class="invalid-feedback">O campo Nome do Portador é obrigatório!</div>'
//         );
//       hasError = true;
//     }

//     if (!payload.user_cpf) {
//       $(`#user_cpf`)
//         .addClass('is-invalid')
//         .after(
//           '<div class="invalid-feedback">O campo CPF do Portador é obrigatório!</div>'
//         );
//       hasError = true;
//     }

//     if (hasError) return;

//     $('.modal-purchase .body-left').html(addressTemplate().html());

//     if (payload.type == 'credit-card') {
//       $('#bp-address-btn').remove();
//     } else if (payload.type == 'boleto-pix') {
//       console.log(sectionShop().find('#cc-address-btn'));
//       $('#cc-address-btn').remove();
//     }
//   }

//   function buyPlanBoletoPix() {
//     $.ajax({
//       method: 'post',
//       url: '/comprar',
//       data: JSON.stringify(payload),
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//         'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
//       },
//     }).done((response) => {
//       console.log(response);
//     });
//   }

//   function handleInputEvent(e) {
//     const input = $(e.target);
//     payload[input.attr('id')] = input.val();
//   }

//   sectionShop().on('click', '.plan-select', function () {
//     payload.plan_id = $(this).attr('id');

//     modalShop().html(paymentsTemplate(payload.plan_id).html());
//   });

//   $('.open-coins-shop').on('click', openPlansModal);
//   sectionShop().on('click', '.inner-close-btn-left', openPlansModal);
//   sectionShop().on('click', '.inner-close-btn-right', closePlansModal);
//   sectionShop().on('click', '.close-btn', closePlansModal);
//   sectionShop().on('click', '.payment .credit-card', () =>
//     openUserInfo('credit-card')
//   );
//   sectionShop().on('click', '.payment .boleto-pix', () =>
//     openUserInfo('boleto-pix')
//   );
//   sectionShop().on('click', '#user-info-btn', openAddressInfo);
//   sectionShop().on('click', '#bp-address-btn', buyPlanBoletoPix);
//   sectionShop().on('input', 'input', handleInputEvent);
// });
